<template>
  <n-config-provider class="h-full" :theme-overrides="themeOverrides">
    <n-global-style />
    <n-message-provider class="h-full">
      <div class="w-full box-border min-h-screen flex flex-col h-full">
        <div class="my-2 px-4" v-if="!(meta?.hidenPageHead ?? false)">
          <PageHeader />
        </div>
        <router-view />
      </div>
    </n-message-provider>
  </n-config-provider>
</template>

<script setup lang="ts">
import { darkTheme } from 'naive-ui';
import { computed, nextTick, onMounted, ref } from 'vue';

import PageHeader from './components/PageHeader.vue';
import { useAppStore } from './store';
import { useRoute, useRouter } from 'vue-router';
import { NConfigProvider, GlobalThemeOverrides,GlobalTheme } from 'naive-ui'

const meta = ref<RouteMeta>({})

const appStore = useAppStore();
type RouteMeta = {
  hidenPageHead?: boolean,
  [propname:string]:any
}
// const theme:GlobalTheme = computed(() => {
//   if (appStore.theme == 'dark') {
//     return darkTheme;
//   } else {
//     return {};
//   }
// });

const themeOverrides: GlobalThemeOverrides = {
  Menu: {
    itemColorActive: '#a9cfe7',
    itemColorHover:"#a9cfe7",
    itemColorActiveHover:"#a9cfe7",
    itemTextColorActive:"#373e41",
    itemTextColorActiveHover:"373e41"
  }
}
onMounted(async() => {
  const router = useRouter()
  await router.isReady()

  meta.value = router.currentRoute.value.meta

})
</script>
<style scoped>
html,
body,
#app {
  height: 100%;
}
</style>
