// import axios from 'axios'
// import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
// import { Message } from '@/utils/tips';
// import { config } from 'process';
// import { userType } from '@/types';

// const DEAFULT_LOADING = false
// interface HYRequestInterceptors<T = AxiosResponse> {
//   requestInterceptor?: (config: AxiosRequestConfig) => AxiosRequestConfig & undefined
//   requestInterceptorCatcher?: (error: any) => any
//   responseInterceptor?: (res: T) => T
//   responseInterceptorCatcher?: (error: any) => any
// }
export type responseDataType<T = any> = {
  data: T
  code: Number | string
  message: string
  status: Number | string

}
// //请求配置添加
// interface HYRequestConfig<T = AxiosResponse> extends AxiosRequestConfig {
//   interceptors?: HYRequestInterceptors<T>
//   showLoading?: boolean
//   loadingText?: String
// }
// class Request {
//   instance: AxiosInstance
//   showLoading: Boolean
//   loadingText?: String & undefined
//   interceptors?: HYRequestInterceptors
//   constructor(config: HYRequestConfig) {
//     this.instance = axios.create(config);
//     this.showLoading = config.showLoading ?? DEAFULT_LOADING
//     this.instance.interceptors.request.use(
//       this.interceptors?.requestInterceptor,
//       this.interceptors?.requestInterceptorCatcher
//     )
//     // 使用响应拦截器
//     this.instance.interceptors.response.use(
//       this.interceptors?.responseInterceptor,
//       this.interceptors?.responseInterceptorCatcher
//     )
//     this.instance.interceptors.request.use(
//       (config) => {
//         console.log("config",config);
//         if (sessionStorage.getItem('Token')) {
//           config.headers['user-token'] = sessionStorage.getItem('Token')
//         }
//         if (this.showLoading) {
//           Message.loading(this.loadingText ?? '正在处理中')
//         }
//         return config
//       },
//       (err) => {
//         return err
//       }
//     )
//     this.instance.interceptors.response.use(
//       (res) => {
//         // 讲loading移除
//         if (this.showLoading) {
//           Message.destroyAll()
//           //重制loading状态
//           this.showLoading = DEAFULT_LOADING
//         }
//         return res.data
//       },
//       (err) => {
//         // 讲loading移除
//         Message.error(err?.data?.message, { duration: 2500 })
//         return err
//       }
//     )

//   }
//   request<T>(config: HYRequestConfig<T>): Promise<T> {
//     return new Promise((resolve, reject) => {
//       // 单个请求对请求config的处理
//       if (config.interceptors?.requestInterceptor) {
//         config = config.interceptors.requestInterceptor(config)
//       }
//       // 判断是否需要显示loading
//       if (config.showLoading === false) {
//         this.showLoading = config.showLoading
//       }

//       this.instance
//         .request<any, T>(config)
//         .then((res) => {
//           // 1.单个请求对返回数据的处理
//           if (config.interceptors?.responseInterceptor) {
//             // res = config.interceptors.responseInterceptor(res)
//           }
//           // 2.将showLoading设置成true，这样不会影响到下一个请求
//           this.showLoading = DEAFULT_LOADING;
//           // 3.将结果resolve返回出去
//           resolve(res)
//         }).catch(err => {
//           // 将showLoading设置成true，这样不会影响到下一个请求
//           this.showLoading = DEAFULT_LOADING;
//           reject(err)
//           return err
//         })
//     })
//   }

// }
// let Instance = new Request(
//   {
//     baseURL: '',
//     timeout: 5000
//   })
// export default Instance





import request from '@/utils/axios'
import type { AxiosProgressEvent, AxiosResponse, GenericAbortSignal } from 'axios'
import { Message } from '@/utils/tips';
import router from "../router"


export interface HttpOption {
  url: string
  data?: any
  method?: string
  headers?: any
  loading?: Boolean
  loadText?: any
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  signal?: GenericAbortSignal
  beforeRequest?: () => void
  afterRequest?: () => void
}
export interface Response<T = any> {
  data: T
  message: string | null
  status: string
  code: string | number
}


function http<T = any>(
  { url, data, method, headers, onDownloadProgress, signal, beforeRequest, afterRequest, loading, loadText }: HttpOption,
) {
  console.log("onDownloadProgress", url, data, method, headers, onDownloadProgress, signal, beforeRequest, afterRequest);
  if (loading) {
    console.log(Message);
    Message.loading(loadText ?? '正在处理中', { duration: 25000 })
  }


  const successHandler = (res: AxiosResponse<Response<any>>) => {
    console.log("resresresresres", res);
    Message.destroyAll()
    if (res.data.code === 200) {
      console.log("------", res.data.data);
      return res.data.data
    }

    if (onDownloadProgress) {
      console.log("流完成",res);
      if (!res.data.code && !res.data.message) {
        return res
      }
    }
    // return Promise.reject(res.data)
    Message.error(res.data.message ?? '请求错误')
    //这里到时候再设置场景Tokn过期 或者失效
    // if (res.data.status === 'Unauthorized') {
    //   window.location.reload()
    // }
    if (res.data.code === 40003) {
      // router.replace({
      //   name:'login',
      // });
      sessionStorage.clear()
      router.replace({
        name: 'login'
      })
      return false
    }
    return Promise.reject(res.data)
  }

  // const failHandler = (error: Response<Error>) => {
  //   afterRequest?.()
  //   throw new Error(error?.message || 'Error')
  // }

  beforeRequest?.()

  method = method || 'GET'

  const params = Object.assign(typeof data === 'function' ? data() : data ?? {}, {})

  return method === 'GET' || method.toUpperCase() === 'GET'
    ? request.get(url, { params, signal, onDownloadProgress }).then(successHandler)
    : request.post(url, params, { headers, signal, onDownloadProgress }).then(successHandler)
}

export default http;