import { defineStore } from 'pinia';

import { getTokentoUser, loginApi, LoginData } from '@/api/user';
import { UserRead } from '@/types/schema';
import { clearCookie } from '@/utils/auth';

import { UserState } from '../types';
import { responseDataType } from '@/api/axiosConfig';
import { userType } from '@/types';


// interface HumanData {
//   id: number;
//   name: string;
//   phone: string;
//   token?: string; // 将token属性声明为可选的
// }
const useUserStore = defineStore('user', {
  state: (): UserState => ({
    user: null,
    savedUsername: null,
    savedPassword: null,
  }),
  getters: {
    userInfo(state: UserState): userType | null {
      return state.user;
    },
  },
  persist: {
    enabled: true, // 开启数据缓存
  },
  actions: {
    // Set user's information
    setInfo(user: any) {
      this.$patch({ user });
    },

    setSavedLoginInfo(phone: string, password: string) {
      this.$patch({ savedUsername: phone, savedPassword: password });
    },

    // Reset user's information
    resetInfo() {
      this.$reset();
    },

    // Get user's information
    // async fetchUserInfo() {
    //   const result = (await getUserInfoApi()).data;
    //   // console.log("!!!@@@",result)
    //   this.setInfo(result);
    // },

    // Login
    async loginToken(Token:string) {
      sessionStorage.setItem('Token', Token?? '')
      try {
        let data = (await getTokentoUser())
        sessionStorage.setItem('user', data['user'])
        let userData = data['user']
        userData['is_superuser'] = userData?.is_admin ?? 0
        this.user = userData
        console.log("loginAPI", this.user)
      } catch (err) {
        clearCookie();
      }
    },
    async login(loginForm: LoginData) {
      try {
        console.log("请求开始");

        let data = (await loginApi(loginForm))
        // if (condition) {

        // }
        console.log("请求结束", data);

        // if (condition) {

        // }
        sessionStorage.setItem('Token', data?.token ?? '')
        sessionStorage.setItem('user', data)
        let userData = data
        userData['is_superuser'] = userData?.is_admin ?? 0
        this.user = userData
        console.log("loginAPI", this.user)
        // this.setInfo(data.data);
        // setToken(res.data.token);
      } catch (err) {
        clearCookie();
        throw new Error("登录失败");
        
      }
    },

    // Logout
    async logout() {
      // try {
      //   await logoutApi();
      // } finally {
      //   this.resetInfo();
      //   clearCookie();
      // }
      sessionStorage.clear()
      this.setInfo(null)
    },
  },
});

export default useUserStore;
