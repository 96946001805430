// import axios from 'axios';


import axios from "../api/axiosConfig"


import { LimitSchema, UserCreate, UserRead } from '@/types/schema';


import ApiUrl from './url';
import { AxiosProgressEvent } from "axios";
import { createUser } from "@/types";

export interface LoginData {
  phone: string;
  password: string;
}

export function deleteConvBoxApi(data: any) {
  return axios({
    url: ApiUrl.deleteConvBox,
    method: 'get',
    loading: true,
    loadText: '正在删除聊天',
    data
  })
}



export function updateConvBox(data: any) {
  return axios({
    url: ApiUrl.updateConvBox,
    method: 'post',
    loading: true,
    loadText: '正在更新聊天室',
    data
  })
}
//登录接口
export function loginApi(data: LoginData) {
  console.log("axios", axios);
  return axios({
    url: ApiUrl.Login,
    method: 'post',
    loading: true,
    data
  });
}

//获取生成聊天内容
export interface chatBoxData {
  name: string;
  model_id?: any
}
export function createBox(data: chatBoxData) {
  return axios({
    url: ApiUrl.createChat,
    method: 'post',
    loading: true,
    loadText: '聊天生成中',
    data
  });
}

//聊天列表
export function chatList() {
  return axios({
    url: ApiUrl.chatList,
    method: 'get',
    loading: true,
    loadText: '获取聊天列表中'
  });
}

export function chatDefault(id?: number | string, requestText: string = 'box_id') {
  return axios({
    url: `${ApiUrl.chatDefault}?${requestText}=${id}`,
    method: 'get',
    loading: true,
    loadText: '获取聊天历史中'
  });
}
interface sendMsgI {
  data: any,
  onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void
  beforeRequest?: any
}
// ,onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void}
export function sendChatMsg(PostData: sendMsgI) {
  console.log("PostDataPostData", PostData);

  return axios({
    url: `${ApiUrl.sendChat}`,
    method: 'post',
    loading: true,
    beforeRequest: PostData?.beforeRequest ?? undefined,
    data: PostData?.data ?? undefined,
    onDownloadProgress: PostData.onDownloadProgress,
    loadText: '发送消息中'
  });
}
export type resetPasswordType = {
  name: string | number | null,
  phone: string | number,
  password: string,
  password2: string,
  [propName: string]: any

}
//创建数字人任务
export function createHumanApi(data: any) {
  return axios({
    url: `/api/human/create`,
    method: 'post',
    loading: true,
    data,
    loadText: '提交消息中'
  })
}

export function getTokentoUser() {
  return axios({
    url:'/api/user/rights',
    method:'get',
    loading: false
  })
}


//查看数字人任务列表
export function getHumanTaskApi(data: any) {
  return axios({
    url: `/api/human/list`,
    method: 'get',
    loading: true,
    data,
    loadText: '获取任务列表中'
  })
}
export function resetUserPasswordApi(data: resetPasswordType) {
  return axios({
    url: `${ApiUrl.resetPassword}`,
    method: 'post',
    loading: true,
    data,
    loadText: '正在修改密码'
  })
}

export function getModel() {
  return axios({
    url: `${ApiUrl.modelList}`,
    method: 'get',
  })
}


export function getAllUserApi() {
  return axios({
    url: `${ApiUrl.allUser}`,
    method: 'get',

  })
}

export function deleteUserApi(id: any) {
  return axios({
    url: `${ApiUrl.deleteUser}`,
    method: 'post',
    data: { id }
  })
}

export function registerApi(data: createUser) {
  return axios({
    url: `${ApiUrl.Register}`,
    method: 'post',
    data
  })
}
export function roleListAPI() {
  return axios({
    url: `${ApiUrl.roleList}`,
    method: 'get',
  })
}
export function editUserAPI(data: any) {

  return axios({
    url: `${ApiUrl.adminEditUser}`,
    method: 'post',
    data
  })
}


export function getPreload(data: any) {
  console.log(4445566, ApiUrl.preload);

  return axios({
    url: `${ApiUrl.preload}`,
    method: 'get',
    data
  })
}


//获取套餐列表
export function getMealList(data:any={}){
  return axios({
    url: `${ApiUrl.mealList}`,
    method: 'get',
    data
  })
}
export function setmeal(data:any={}) {
  return axios({
    url: `${ApiUrl.setmeal}`,
    method: 'post',
    data
  })
}

// export function registerApi(userInfo: UserCreate) {
//   return axios.post<UserRead>(ApiUrl.Register, userInfo);
// }

// export function logoutApi() {
//   return axios.post<any>(ApiUrl.Logout);
// }

// export function getUserInfoApi() {
//   return axios.get<UserRead>(ApiUrl.UserInfo);
// }

// export function getAllUserApi() {
//   return axios.get<UserRead[]>(ApiUrl.UserList);
// }

// export function deleteUserApi(user_id: number) {
//   return axios.delete(ApiUrl.UserList + `/${user_id}`);
// }

// export function resetUserPasswordApi(user_id: number, new_password: string) {
//   return axios.patch(ApiUrl.UserList + `/${user_id}/reset-password`, null, {
//     params: { new_password },
//   });
// }

// export function updateUserLimitApi(user_id: number, limit: LimitSchema) {
//   return axios.post(ApiUrl.UserList + `/${user_id}/limit`, limit);
// }

// export function updateUserInfoApi(userInfo: Partial<UserUpdate>) {
//   return axios.patch<UserRead>(ApiUrl.UserInfo, userInfo);
// }
