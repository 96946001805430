import { MdMore } from '@vicons/ionicons4';
import { NButton, NDropdown, NIcon, NInput, NSelect, SelectOption } from 'naive-ui';
import { h, ref } from 'vue';

import { i18n } from '@/i18n';
import useUserStore from '@/store/modules/user';
import useconversationStore from "@/store/modules/conversation"
import { ChatConversationDetail } from '@/types/custom';
import { ChatModels, ConversationSchema } from '@/types/schema';
import { Dialog } from '@/utils/tips';
import { ConversationDefault } from '@/types/echarts';
import { getModel } from '@/api/user';
import { subPreloadType } from '@/store/types';
import { usePreloadStore } from '@/store';

const t = i18n.global.t as any;

const modelNameMap = {
  'text-davinci-002-render-sha': t('commons.shaModel'),
  'text-davinci-002-render-paid': t('commons.paidModel'),
  'gpt-4': t('commons.gpt4Model'),
};

const getModelNameTrans = (model_name: keyof typeof modelNameMap) => {
  return modelNameMap[model_name] || "Default";
};

const getCountTrans = (count: number): string => {
  return count == -1 ? t('commons.unlimited') : `${count}`;
};
const newDropdownRenderer = (
  proload: subPreloadType,
  handleDeleteConversation: (id?: string|number) => void,
  handleChangeConversationTitle: (id?: string|number) => void
) =>
  h(
    NDropdown,
    {
      trigger: 'hover',
      options: [
        {
          label: t('commons.delete'),
          key: 'delete',
          props: {
            onClick: () => handleDeleteConversation(proload.id),
          },
        },
        {
          label: t('commons.rename'),
          key: 'rename',
          props: {
            onClick: () => handleChangeConversationTitle(proload.id),
          },
        },
      ],
    },
    {
      default: () =>
        h(
          NButton,
          {
            size: 'small',
            quaternary: true,
            circle: true,
          },
          { default: () => h(NIcon, null, { default: () => h(MdMore) }) }
        ),
    }
  );
const dropdownRenderer = (
  conversation: ConversationDefault,
  handleDeleteConversation: (conversation_id?: string) => void,
  handleChangeConversationTitle: (conversation_id?: string) => void
) =>
  h(
    NDropdown,
    {
      trigger: 'hover',
      options: [
        {
          label: t('commons.delete'),
          key: 'delete',
          props: {
            onClick: () => handleDeleteConversation(conversation.box_id),
          },
        },
        {
          label: t('commons.rename'),
          key: 'rename',
          props: {
            onClick: () => handleChangeConversationTitle(conversation.box_id),
          },
        },
      ],
    },
    {
      default: () =>
        h(
          NButton,
          {
            size: 'small',
            quaternary: true,
            circle: true,
          },
          { default: () => h(NIcon, null, { default: () => h(MdMore) }) }
        ),
    }
  );

const popupInputDialog = (title: string, placeholder: string, callback: (inp: string) => Promise<any>, success: () => void, fail: () => void) => {
  let input = '';
  const secondInput: string | undefined = undefined;
  const d = Dialog.info({
    title: title,
    positiveText: t('commons.confirm'),
    negativeText: t('commons.cancel'),
    content: () =>
      h(NInput, {
        onInput: (e) => (input = e),
        autofocus: true,
        placeholder: placeholder,
      }),
    onPositiveClick() {
      d.loading = true;
      return new Promise((resolve) => {
        callback(input)
          .then(() => {
            success();
            resolve(true);
          })
          .catch(() => {
            fail();
            resolve(true);
          })
          .finally(() => {
            d.loading = false;
          });
      });
    },
  });
};

const getAvailableModelOptions = (): SelectOption[] => {
  // const userStore = useUserStore();
  const preloadStore = usePreloadStore()

  // const options = [{ label: t('commons.shaModel'), value: 'text-davinci-002-render-sha' }];
  // if (userStore.user?.can_use_paid)
  //   options.push({
  //     label: t('commons.paidModel'),
  //     value: 'text-davinci-002-render-paid',
  //   });
  // if (userStore.user?.can_use_gpt4) options.push({ label: t('commons.gpt4Model'), value: 'gpt-4' });
  // console.log(options);
  // const result = await getModel()

  const options: SelectOption[] = []
  
  if (preloadStore.chatModelList.length) {
    preloadStore.chatModelList.forEach((el: { name: any; id: any; }) => {
      options.push({
        label: el.name,
        value: el.id
      })
    });
    console.log("options",options);
    
    return options;
  } else {
    return []
  }

};


const popupNewConversationDialog = (callback: (_conv_title: string, _conv_model: string|number) => Promise<any>) => {
  let convTitle = '';
  const preloadStore = usePreloadStore()
  console.log(preloadStore.chatModelList);
  
  let convModel = ref(preloadStore.chatModelList[0]['id']);
  


  const d = Dialog.info({
    title: t('commons.newConversation'),
    positiveText: t('commons.confirm'),
    negativeText: t('commons.cancel'),
    // content: () =>
    //   h(NInput, { onInput: (e) => (input = e), autofocus: true, placeholder: placeholder, }),

    // 用一个 NInput 和一个 NSelect
    content: () =>
      h(
        'div',
        {
          style: 'display: flex; flex-direction: column; gap: 16px;',
        },
        [
          h(NInput, {
            onInput: (e) => (convTitle = e),
            autofocus: true,
            placeholder: t('tips.conversationTitle'),
          }),
          h(NSelect, {
            placeholder: t('tips.whetherUsePaidModel'),
            value:convModel.value,
            'onUpdate:value': (value: number) => {
              console.log("^^^%%",value);
              
              return convModel.value = value},
            options: getAvailableModelOptions(),
          }),
        ]
      ),
    onPositiveClick() {
      d.loading = true;
      return new Promise((resolve) => {
        callback(convTitle, convModel.value)
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(true);
          })
          .finally(() => {
            d.loading = false;
          });
      });
    },
  });
};

const popupChangeConversationTitleDialog = (
  id: string|number,
  callback: (title: string) => Promise<any>,
  success: () => void,
  fail: () => void
) => {
  popupInputDialog(t('commons.rename'), t('tips.rename'), callback, success, fail);
};

const popupResetUserPasswordDialog = (callback: (password: string) => Promise<any>, success: () => void, fail: () => void) => {
  popupInputDialog(t('commons.resetPassword'), t('tips.resetPassword'), callback, success, fail);
};

export {
  dropdownRenderer,
  getCountTrans,
  getModelNameTrans,
  modelNameMap,
  newDropdownRenderer,
  popupChangeConversationTitleDialog,
  popupNewConversationDialog,
  popupResetUserPasswordDialog,
};
