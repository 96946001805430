// 聊天模块的全局状态管理
import { defineStore } from 'pinia';
import { preloadType, contentType, subPreloadType } from '../types';
import { chatModels, userType } from '@/types';
import { deleteConvBoxApi, getModel, updateConvBox } from '@/api/user';
interface preloadState {
  currentPreload: preloadType | null,
  subPreload: subPreloadType[],
  perform: contentType[] | null
  chatModelList: chatModels[]
  [propname: string]: any
}
const usePreloadStore = defineStore('preload', {
  state: (): preloadState => ({
    subPreloadId: '',
    perform: null,
    currentPreload: null,
    subPreload: [],
    chatModelList: [] as Array<chatModels>,//存储model数据
    chatMessageList:[]
  }),
  getters: {
    getPreload(state: preloadState): preloadType {
      return state.currentPreload ?? {
        name: '',
        id: 0,
        type: '',
        preload: null
      };
    },
    getSubPreload(state: preloadState) {
      return state.subPreload ?? {};
    }
  },
  persist: {
    enabled: true, // 开启数据缓存
  },
  actions: {
    async deleteBox(id: string | number) {
      let res = await deleteConvBoxApi({ box_id: id });

      for (let index = 0; index < this.subPreload.length; index++) {
        let item = this.subPreload[index]
        if (item?.id == id) {
          this.subPreload.splice(index, 1)
          break
        }
      }
      return res
    },

    async getChatModelAction() {
      if (!this.chatModelList?.length) {
        const list = await getModel()
        this.$patch({ chatModelList: list });
      }
      console.log("当前可用模块有", this.chatModelList)
    },
    async chatBoxTitle(id: string | number, title: string) {
      let res = await updateConvBox({ box_id: id, name: title });

      for (let index = 0; index < this.subPreload.length; index++) {
        let item = this.subPreload[index]
        if (item?.id == id) {
          this.subPreload[index]['name'] = title

          break
        }
      }
    },
    //聊天列表
    setMessageList(chatMessageList:any){
      this.$patch({ chatMessageList });

    },
    // Set user's information
    setsubData(subPreload: any) {
      delete subPreload['key']
      delete subPreload['label']
      this.$patch({ subPreload });
    },
    setData(currentPreload: any) {
      delete currentPreload['key']
      delete currentPreload['label']
      this.$patch({ currentPreload })
    },
    setChatModel(chatModelList: any) {

      this.$patch({ chatModelList })
    }

  }

})
export default usePreloadStore;