import { createPinia } from 'pinia';

import useAppStore from './modules/app';
import useConversationStore from './modules/conversation';
import piniaPluginPersist from 'pinia-plugin-persist'
import useUserStore from './modules/user';
import usePreloadStore from "./modules/preload"

const pinia = createPinia();
pinia.use(piniaPluginPersist)

export { useAppStore, useConversationStore,useUserStore ,usePreloadStore};
export default pinia;
