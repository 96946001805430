//使用单例模式来进行显示session类
class SS {
  private static _instance:SS
  private constructor() {}
  public static getInstance(): SS {
    if (!this._instance) {
      this._instance = new SS();
    }
    return this._instance;
  }
  public get(name:string){
    return sessionStorage.getItem(name)
  }
  public set(name:string,Obj:any){
    sessionStorage.setItem(name,Obj)
  }
}
//获取session
export const getSession =(name:string) =>{
  return SS.getInstance().get(name)
} 
//设置session
export const setSession =<T = any>(name:string,obj:T)=>{
  SS.getInstance().set(name,obj)
} 