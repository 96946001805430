import axios, { type AxiosResponse } from 'axios'
import { getSession } from './localhostUtils'


const service = axios.create({
  baseURL: '',
})

service.interceptors.request.use(
  (config) => {
    let token = getSession('Token') ?? undefined
    if (token)
      config.headers['user-token'] = `${token}`
    return config
  },
  (error) => {
    return Promise.reject(error.response)
  },
)

service.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse | any => {
    console.log("response请求响应", response);



    if (response.status === 200) {
      console.log("return 数据", response);
      return response
    }

    throw new Error(response.status.toString())
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default service
