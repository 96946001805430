import type { LocationQueryRaw, Router } from 'vue-router';

import { i18n } from '@/i18n';
import { useUserStore } from '@/store';
import { Message } from '@/utils/tips';
const t = i18n.global.t as any;

// 在 userLoginInfo 之后，此时要么登录成功，要么未登录
export default function setupPermissionGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();

    console.log("totototo",to);
    if (to.query.token) {
      await userStore.loginToken(to.query.token + '')
    }
    if (!to.meta.requiresAuth){
      console.log("********");
      
      next();
    } else {
      console.log("to route",to)
      //Token 不存在则重新登录
      if (!sessionStorage.getItem('Token') && !sessionStorage.getItem('user')) {
        Message.error(t('errors.userNotLogin'));
        next({
          name: 'login',
          query: {
            redirect: to.name,
            ...to.query,
          } as LocationQueryRaw,
        });
        next()

      } else {
        // if (to.meta.roles.find((role) => role === userStore.user.role) === ) {
        //   if (userStore.user.is_superuser) next();
        //   else next({ name: "403" });
        // } else next();
        if (!userStore.user) {
          let _user = JSON.parse(JSON.stringify(sessionStorage.getItem('user')))??null
          userStore.setInfo({user:_user})
        }
        const role = userStore.user?.is_superuser ? 'superuser' : 'user';
        if (to.meta.roles.find((r) => r === role) === undefined) {
          next({ name: '403' });
        } else next();
      }
    }
  });
}
