import { defineStore, mapActions } from 'pinia';
import { chatList, chatDefault, getModel, roleListAPI, deleteConvBoxApi, updateConvBox } from "@/api/user"
import { deleteConversationApi, getAllConversationsApi, getConversationHistoryApi, setConversationTitleApi } from '@/api/chat';
import { ChatMessage } from '@/types/custom';
import { ConversationSchema } from '@/types/schema';
import { responseDataType } from '@/api/axiosConfig';
import { ChatConversationDetail, chatModels, roleTypes } from '@/types';


const useConversationStore = defineStore('conversation', {
  state: (): any => ({
    conversations: [] as Array<ChatConversationDetail>,
    conversationDetailMap: {} as Record<string | number, ChatConversationDetail>, // conv_id => ChatConversationDetail
    chatModelList: [] as Array<chatModels>,//存储model数据
    roleList: [] as Array<roleTypes>,
    box_id:localStorage.getItem('chatObj')??''as String
  }),
  getters: {
     async getRoleList(state):Promise<Array<roleTypes>> {
      if (!state.roleList.length) { 
       await state.roleListAction()
      }
      return state.roleList
    }
  },
  actions: {
    async roleListAction() {
      const list = await roleListAPI()
      console.log("roleOptions",list);
      
      this.$patch({
        roleList: list
      })
    },
    updateBoxId(value:string){
      this.$patch({
        box_id:value
      })
    localStorage.setItem('chatObj', value)

    },
    async getChatModelAction() {
      const list = await getModel()
      this.$patch({ chatModelList: list });
    },
    async fetchAllConversations() {
      // const result = (await getAllConversationsApi()).data;
      const result = (await chatList());
      this.$patch({ conversations: result });
    },

    async fetchConversationHistory(conversation_id: string) {
      // 解析历史记录

      if (this.conversationDetailMap[conversation_id]) {
        return this.conversationDetailMap[conversation_id];
      }


      const result = (await chatDefault(conversation_id));
      console.log("chatDefault", result)
      const conv_detail: ChatConversationDetail = {
        box_id: conversation_id,
        message_box: result.message_box??[],
        name: result.name,
        user_id: result.user_id
      };

      // for (const message_id in result.message_box) {

      //   conv_detail.mapping[message_id] = {
      //     id: message_id,
      //     parent: current_msg.parent,
      //     children: current_msg.children,
      //     author_role: current_msg.message?.author?.role,
      //     model_slug: current_msg.message?.metadata?.model_slug,
      //     message: current_msg.message?.content?.parts.join('\n\n'),
      //   } as ChatMessage;
      // }
      console.log(conversation_id, conv_detail);
      this.$patch({
        conversationDetailMap: {
          [conversation_id]: conv_detail,
        },
      });

    },
    pushConversationDetailMap(conversation_id: string,result:ChatConversationDetail){
      const conv_detail: ChatConversationDetail = {
        box_id: conversation_id,
        message_box: result.message_box??[],
        name: result.name,
        user_id: result.user_id
      };
      this.$patch({
        conversationDetailMap: {
          [conversation_id]: conv_detail,
        },
      });
    },
    addConversation(conversation: ConversationSchema) {
      this.conversations.push(conversation);
    },

    async deleteConversation(conversation_id: string) {
      await deleteConvBoxApi({box_id:conversation_id});
      delete this.conversationDetailMap[conversation_id];
      this.conversations = this.conversations.filter((conv: any) => conv.box_id !== conversation_id);
    },

    async changeConversationTitle(conversation_id: string, title: string) {
      await updateConvBox({box_id:conversation_id, name:title});
      await this.fetchAllConversations();
      if (this.conversationDetailMap[conversation_id]) {
        this.conversationDetailMap[conversation_id].title = title;
      }
    },

    // 仅当收到新信息时调用，为了避免重复获取整个对话历史
    addMessageToConversation(conversation_id: string, sendMessage: ChatMessage, recvMessage: ChatMessage) {
      console.log(conversation_id,sendMessage,recvMessage);
      console.log(this.conversationDetailMap[conversation_id]);

      if (!this.conversationDetailMap[conversation_id]) {
      console.log(conversation_id,sendMessage,recvMessage);

        this.conversationDetailMap[conversation_id] = {
          box_id:conversation_id,
          message_box:[]
        }
      }
      const conv_detail = this.conversationDetailMap[conversation_id]['message_box'];
      console.log(sendMessage,recvMessage,conv_detail);
      
      conv_detail.push(sendMessage)
      conv_detail.push(recvMessage)
      
      // conv_detail[sendMessage.id] = sendMessage;
      // conv_detail[recvMessage.id] = recvMessage;

      // 这里只有在新建对话时调用
      // if (conv_detail.current_node === null) {
      //   conv_detail.current_node = recvMessage.id;
      // } else {
      //   const lastTopMessage = conv_detail.mapping[conv_detail.current_node];
      //   sendMessage.parent = lastTopMessage.id;
      //   lastTopMessage.children.push(sendMessage.id);
      //   conv_detail.current_node = recvMessage.id;
      // }
      // sendMessage.children = [recvMessage.id];
      // recvMessage.parent = sendMessage.id;
    },
  },
});

export default useConversationStore;
