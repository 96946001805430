import { chatDefault } from "./user";

const pre = '/api'
enum ApiUrl {
  // Register = '/auth/register',
  Login = `/api/user/login`,
  Logout = '/auth/logout',
  UserInfo = '/user/me',

  Conversation = '/conv',
  UserList = '/user',




  ServerStatus = '/status',
  SystemInfo = '/system/info',
  SystemRequestStatistics = '/system/request_statistics',
  ProxyLogs = '/system/proxy_logs',
  ServerLogs = '/system/server_logs',






  createChat = `/api/chatgpt/create`,

  chatList = `/api/chatgpt/list`,
  chatDefault = `/api/chatgpt/history`,
  sendChat = `/api/chatgpt/user_chat`,
  resetPassword = `/api/user/edit`,
  modelList = `/api/user/models`,
  allUser = `/api/admin/user/list`,
  deleteUser = `/api/admin/user/delete`,
  Register = `/api/admin/user/create`,
  roleList = `/api/admin/role/list`,
  adminEditUser = `/api/admin/user/edit`,
  deleteConvBox = `/api/chatgpt/delete`,
  updateConvBox = `/api/chatgpt/update`,

  preload = `/api/chatgpt/preload`,

  mealList=`/api/admin/user/packages`,
  setmeal = `/api/admin/user/package`



}

export default ApiUrl;
